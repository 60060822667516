import * as React from "react"
import { Layout } from "@bw/layouts"
import { Hero } from "@bw/modules"
import { Button, Seo } from "@bw/bits"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout noFooter>
      <Seo title={t("404.title")} />
      <Hero
        title={t("404.title")}
        excerpt={t("404.subtitle")}
        background="var(--primary)"
        button={<Button to="/" label={t("404.button")} inversed />}
        contentSize="490px"
      />
    </Layout>
  )
}

export default NotFoundPage
